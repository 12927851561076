import React, { useState, useEffect } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Projects.scss';

const Projects = () => {
    const [activeFilter, setActiveFilter] = useState('All');
    const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
    const [projects, setProjects] = useState([]);
    const [filterProjects, setFilterProjects] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const query = '*[_type == "projects"]{_id, title, description, imgUrl, tags, slug}';
        client.fetch(query).then((data) => {
            setProjects(data);
            setFilterProjects(data);
        });
    }, []);

    const handleWorkFilter = (item) => {
        setActiveFilter(item);
        setAnimateCard([{ y: 100, opacity: 0 }]);
        setTimeout(() => {
            setAnimateCard([{ y: 0, opacity: 1 }]);
            if (item === 'All') {
                setFilterProjects(projects);
            } else {
                setFilterProjects(projects.filter((project) => project.tags.includes(item)));
            }
        }, 500);
    };

    const handleNavigate = (slug) => {
        console.log("Navigating to slug:", slug); // Debugging line
        if (slug) {
            navigate(`/projects/${slug.current}`);
        } else {
            console.error("Project slug is undefined");
        }
    };

    return (
        <>
            <h2 className='head-text'>
                My <span>Projects</span> Section
            </h2>

            <div className="app__projects-filter">
                {['Real-time hardware systems', 'Software projects', 'Blender Projects', 'Mobile App', 'All'].map((item, index) => (
                    <div
                        key={index}
                        onClick={() => handleWorkFilter(item)}
                        className={`app__projects-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
                    >
                        {item}
                    </div>
                ))}
            </div>
            <motion.div
                animate={animateCard}
                transition={{ duration: 0.5, delayChildren: 0.5 }}
                className='app__projects-portfolio'
            >
                {filterProjects.map((project, index) => (
                    <div className='app__projects-item app__flex' key={index}>
                        <div className='app__projects-img app__flex'>
                            <img src={urlFor(project.imgUrl)} alt={project.title} />

                            <motion.div
                                className="app__projects-hover app__flex"
                                initial={{ opacity: 0 }}
                                whileHover={{ opacity: 1 }}
                                transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                            >
                                <motion.div
                                    whileInView={{ scale: [1, 0.9] }}
                                    whileHover={{ scale: [1, 0.9] }}
                                    transition={{ duration: 0.25 }}
                                    className='app__flex'
                                    onClick={() => handleNavigate(project.slug)}
                                >
                                    <AiFillEye />
                                </motion.div>
                            </motion.div>
                        </div>

                        <div className='app__projects-content app__flex'>
                            <h4 className='bold-text'>{project.title}</h4>
                            <p className='p-text' style={{ marginTop: 10 }}>{project.description}</p>
                            <div className='app__projects-tag app__flex'>
                                <p className='p-text'>{project.tags[0]}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </motion.div>
        </>
    );
};

export default AppWrap(
    MotionWrap(Projects, 'app__projects'),
    'projects',
    'app__primarybg',
);
