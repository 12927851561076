import React, { useState } from 'react';
import './SnowplowRobot.css';
import { motion } from 'framer-motion';
import snow from '../../assets/Snowplow/snow.jpg';
import snowtechh from '../../assets/Snowplow/snowtechh.png';
import hardwareArchitecture from '../../assets/Snowplow/hardware-architecture.png';
import bleServer from '../../assets/Snowplow/user-interface.png';
import userInterface from '../../assets/Snowplow/user-interface.png';
import jetsonNano from '../../assets/Snowplow/jetson-nano.jpg';
import component1 from '../../assets/Snowplow/component1.png';
import component2 from '../../assets/Snowplow/component2.png';
import component3 from '../../assets/Snowplow/component3.png';
import component4 from '../../assets/Snowplow/component4.png';
import component5 from '../../assets/Snowplow/component5.png';
import component6 from '../../assets/Snowplow/component6.png';
import component7 from '../../assets/Snowplow/component7.png';
import obj from '../../assets/Snowplow/obj.MP4';
import { FaLinkedin } from "react-icons/fa";


const SnowplowRobot = () => {
    const [fullScreenImage, setFullScreenImage] = useState(null);

    const handleImageClick = (src) => {
        setFullScreenImage(src);
    };

    const handleCloseImage = () => {
        setFullScreenImage(null);
    };

    return (
        <motion.div className="container"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{duration: 0.5}}
        >
            <div className="header">
                <div className="header-left">
                    <img src={snow} alt='snow' className="background-image"/>
                    <div className="title-container">
                        <h1 className="title">Snowplow Robot Project</h1>
                        <p className="subtitle">Hardware & Automation</p>
                    </div>
                </div>
            </div>
            <p className="text">
                The semi-automated snowplow robot leverages NVIDIA Jetson Nano and ESP32 for enhanced control and
                efficiency. This innovative solution assists in snow removal, ensuring safety and convenience during the
                winter season.
            </p>

            <h2 className="section-title">About The Project</h2>
            <p className="text">
                The Snowplow Robot project is a collaborative capstone initiative aimed at developing an autonomous
                snow removal robot. This robot is designed to efficiently clear snow from driveways, sidewalks,
                and other surfaces, providing a safer and more convenient solution for snow management during the winter
                months.
                The project integrates various advanced technologies, including AI and machine learning for object
                detection,
                and real-time notifications to ensure optimal performance and user interaction.
                In the following sections, <span>I will primarily focus on the aspects of the project that I personally contributed to.</span>
                This includes <span>the design and implementation of the motor/motor driver and ESP32 circuitry, the development of the object
                recognition model on the Raspberry Pi camera using the Jetson Nano, and the integration of IR and ultrasonic sensors
                into the robot's architecture.</span> My work also encompassed setting up real-time notifications via a
                BLE server to enhance
                user experience.

                <motion.img className="image" src={snowtechh} alt="snowtechh"
                            initial={{scale: 0.8}}
                            animate={{scale: 1}}
                            transition={{duration: 0.5}}
                            onClick={() => handleImageClick(snowtechh)}
                />
            </p>

            <section className="project-section">
                <h2 className="section-title">Technologies Involved</h2>
                <div className="components-grid">
                    <div className="component">
                        <img src={jetsonNano} alt="Jetson Nano" className="component-image image"
                             onClick={() => handleImageClick(jetsonNano)}/>
                        <p><strong>Jetson Nano Developer Kit</strong>: AI and machine learning applications, 1.48 GHz, 4
                            GB RAM, 64 GB storage.</p>
                    </div>
                    <div className="component">
                        <img src={component1} alt="Component 1" className="component-image image"
                             onClick={() => handleImageClick(component1)}/>
                        <p><strong>ESP32 Controller</strong>: Dual-core, Wi-Fi/Bluetooth, 520KB SRAM, 4MB Flash.</p>
                    </div>
                    <div className="component">
                        <img src={component2} alt="Component 2" className="component-image image"
                             onClick={() => handleImageClick(component2)}/>
                        <p><strong>Ultrasonic Sensor</strong>: Measures 2-400 cm, 5V input.</p>
                    </div>
                    <div className="component">
                        <img src={component3} alt="Component 3" className="component-image image"
                             onClick={() => handleImageClick(component3)}/>
                        <p><strong>RP-Lidar A1</strong>: 360° scanning, 0.15 to 12 meters range.</p>
                    </div>
                    <div className="component">
                        <img src={component4} alt="Component 4" className="component-image image"
                             onClick={() => handleImageClick(component4)}/>
                        <p><strong>7.4V Battery</strong>: 7000mAh, SANYO NCR18650GA cells.</p>
                    </div>
                    <div className="component">
                        <img src={component5} alt="Component 5" className="component-image image"
                             onClick={() => handleImageClick(component5)}/>
                        <p><strong>L298N Motor Driver</strong>: Dual H-Bridge, 7V to 12V input.</p>
                    </div>
                    <div className="component">
                        <img src={component6} alt="Component 6" className="component-image image"
                             onClick={() => handleImageClick(component6)}/>
                        <p><strong>Raspberry Pi Camera</strong>: 8MP, infrared capability.</p>
                    </div>
                    <div className="component">
                        <img src={component7} alt="Component 7" className="component-image image"
                             onClick={() => handleImageClick(component7)}/>
                        <p><strong>IR Sensors</strong>: Detects obstacles, used for close-range detection.</p>
                    </div>
                </div>
            </section>

            <h2 className="section-title">Hardware Architecture</h2>
            <p className="text">
                Our final hardware architecture relies on the collaboration of both the Jetson Nano (our microprocessor)
                and the ESP32 (our microcontroller) optimizing navigation control for enhanced robotic performance. The
                Jetson Nano processes real-time images captured by the Raspberry Pi camera for object detection. It is
                powered by a 7.4V lithium battery. The ESP32 controls the motors using the L298N motor driver and
                manages the blade movement and the salt dispensary unit.
            </p>
            <motion.img className="image" src={hardwareArchitecture} alt="Hardware Architecture"
                        initial={{scale: 0.8}}
                        animate={{scale: 1}}
                        transition={{duration: 0.5}}
                        onClick={() => handleImageClick(hardwareArchitecture)}
            />
            <p className="section-description">
                I personally focused on integrating the DC motors, motor driver, and ESP32 within the robot's
                architecture. This involved creating a cohesive logic flow using ArduinoIDE and the Robotjax library.
            </p>
            <h3 className="section-subtitle">Motor Integration and Logic Flow</h3>
            <ul className="section-list">
                <li><strong>Connection Setup:</strong> Connected the DC motors to the motor driver, and interfaced the
                    motor driver with the ESP32, using the Robotjax library on the Arduino IDE. Assigned GPIO pins and
                    ensured proper cable management.
                </li>
                <li><strong>Basic and Complex Movements:</strong> Implemented basic movements such as moving forward and
                    backward. Developed more complex maneuvers like turning left and right, and making U-turns.
                </li>
                <li><strong>Obstacle Avoidance:</strong> Integrated the ultrasound sensor to provide real-time data for
                    obstacle detection. Programmed the ESP32 to trigger appropriate movements based on sensor input,
                    ensuring the robot can navigate around obstacles effectively.
                </li>
            </ul>
            <h3 className="section-subtitle">Blade and Salt Dispenser Control</h3>
            <ul className="section-list">
                <li><strong>Blade Movement:</strong> Configured an ESP32 to control the blade's movement through a
                    linear actuator. Implemented left-to-right blade movement by sending control signals to the servo
                    motors.
                </li>
                <li><strong>Salt Dispenser:</strong> Developed logic for the ESP32 to control the salt dispenser unit.
                    Enabled 360-degree rotation of the actuator within the salt unit, facilitating the dispersal of salt
                    from the container.
                </li>
            </ul>
            <p className="section-description">
                By combining these components and implementing the control logic, I ensured that the snowplow robot can
                effectively maneuver and perform its intended tasks in real-time.
                The integration of sensors and actuators allows for responsive and adaptive behavior, essential for
                efficient snow removal.
            </p>

            <div className="content-section">
                <h2 className="section-title">Object Recognition Implementation</h2>
                <p className="text">
                    To enhance automation and ensure safety, we integrated an object recognition algorithm designed to
                    identify human beings labeled as 'person'. As shown in the video below, when a person is detected,
                    the robot immediately stops and sends an alert to the application.
                </p>
                <p className="text">
                    I implemented the camera recognition code using the COCO library in Python. The Jetson Nano,
                    connected via USB to the ESP32, processes the video feed. Upon detecting a person, the Jetson Nano
                    informs the ESP32 responsible for motor control, causing the robot to halt its movement.
                    Simultaneously, an alert is sent to the application via the BLE server.
                </p>
                <div className="video-container">
                    <video controls width="100%" height="auto">
                        <source src={obj} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>

            <h2 className="section-title">Graphical User Interface</h2>
            <p className="text">
                The GUI for the snowplow robot includes login authentication, battery status display, remote control
                functionality, and notifications. The user interface is designed to be intuitive and user-friendly.
            </p>
            <motion.img className="image" src={userInterface} alt="User Interface"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 1}}
                        onClick={() => handleImageClick(userInterface)}
            />

            <h2 className="section-title">Real-Time Notifications</h2>
            <p className="text">
                I implemented real-time notifications using a BLE (Bluetooth Low Energy) server. This involved
                setting up the BLE server on the ESP32 to send notifications to a connected mobile device whenever
                specific events or sensor readings were detected. This feature enhances the user experience by providing
                timely updates about the robot's status and any critical issues.
            </p>
            <motion.img className="image" src={bleServer} alt="BLE Server"
                        initial={{y: 50}}
                        animate={{y: 0}}
                        transition={{duration: 0.5}}
                        onClick={() => handleImageClick(bleServer)}
            />

            <h2 className="section-title">Note</h2>
            <p className="text note">
                This was a common capstone project, and the information shared here is what my colleagues were
                comfortable with. My focus was primarily on designing the motors/motor driver and ESP32 circuitry,
                developing the object recognition model on the Raspberry Pi camera using the Jetson Nano, and
                integrating the IR sensors and ultrasonic sensor into the architecture.
            </p>

            <footer className="project-footer">
                <div className="social-links">
                    <a href="https://www.linkedin.com/in/molly-kanyatsi-23a2591b9" target="_blank"
                       rel="noopener noreferrer">
                        <FaLinkedin size={30}/>
                    </a>
                </div>
                <p>© 2024 Molly Kanyatsi. All rights reserved.</p>
            </footer>

            {fullScreenImage && (
                <div className="full-screen-image-container" onClick={handleCloseImage}>
                    <img src={fullScreenImage} alt="Full Screen" className="full-screen-image"/>
                </div>
            )}
        </motion.div>
    );
};

export default SnowplowRobot;
