import React, { useState } from 'react';
import './RealTimeAlarmSystem.css';
import { motion } from 'framer-motion';
import { FaLinkedin, FaGithub } from 'react-icons/fa';
import back from '../../assets/Alarm/back.jpg'
import buzzer from '../../assets/Alarm/buzzer.jpeg';
import pir from '../../assets/Alarm/pir.jpeg';
import oled from '../../assets/Alarm/oled.webp';
import keypad from '../../assets/Alarm/keyboard.png';
import nucleo from '../../assets/Alarm/nucleo.jpeg';
import lab from '../../assets/Alarm/lab.mp4';
import flowchart from '../../assets/Alarm/flowchart.png';
import circuits from '../../assets/Alarm/circuits.png';

const RealTimeAlarmSystem = () => {
    const [fullScreenImage, setFullScreenImage] = useState(null);

    const handleImageClick = (src) => {
        setFullScreenImage(src);
    };

    const handleCloseImage = () => {
        setFullScreenImage(null);
    };

    return (
        <motion.div className="container"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
        >
            <div className="header">
                <div className="header-left">
                    <img src={back} alt="Background" className="background-image" />
                    <div className="title-container">
                        <h1 className="title">Real Time Alarm System</h1>
                        <p className="subtitle">Hardware & Automation</p>
                    </div>
                </div>
            </div>
            <p className="text">
                This real-time alarm system was developed as part of my coursework in the Real-Time Systems Design
                course. The project involved integrating a 4x4 keypad, an LCD display, a PIR motion sensor, and a buzzer
                using an STM32 microcontroller with FreeRTOS for real-time task management.
            </p>

            <h2 className="section-title">About The Project</h2>
            <p className="text">
                The primary goal was to create an interactive and secure alarm system. Users can arm or disarm the
                system by entering a predefined code on the keypad. The LCD displays the system status and user prompts.
                The PIR sensor detects motion, triggering the buzzer to alert any potential intrusion. The red LED turns
                on when the system is armed and the green LED when the system is disarmed.
            </p>

            <section className="project-section">
                <h2 className="section-title">Technologies Involved</h2>
                <p className="text">
                    The project utilized the following components:
                </p>
                <div className="components-grid">
                    <div className="component">
                        <img src={nucleo} alt="Nucleo Card" className="component-image image" onClick={() => handleImageClick(nucleo)} />
                        <p><strong>NUCLEO F446RE development card</strong>: The main platform for development and
                            execution. Equipped with a microcontroller STM32F446RE, which manages tasks of calculation
                            and control, allowing reading and processing of ADC and LCD channels.</p>
                    </div>
                    <div className="component">
                        <img src={keypad} alt="Keypad" className="component-image image" onClick={() => handleImageClick(keypad)} />
                        <p><strong>4x4 Keypad</strong>: Used for user input to arm or disarm the system.</p>
                    </div>
                    <div className="component">
                        <img src={oled} alt="OLED" className="component-image image" onClick={() => handleImageClick(oled)} />
                        <p><strong>OLED LCD Display</strong>: Displays system status and user prompts.</p>
                    </div>
                    <div className="component">
                        <img src={pir} alt="PIR" className="component-image image" onClick={() => handleImageClick(pir)} />
                        <p><strong>PIR Motion Sensor</strong>: Detects motion and triggers the alarm.</p>
                    </div>
                    <div className="component">
                        <img src={buzzer} alt="Buzzer" className="component-image image" onClick={() => handleImageClick(buzzer)} />
                        <p><strong>Buzzer</strong>: Provides audible alerts for security breaches.</p>
                    </div>
                </div>
            </section>

            <h2 className="section-title">System Design</h2>
            <p className="text">
                The realization of this laboratory involved three main aspects: continuing the design of the alarm
                system from Lab 3, integrating the new design into the existing code, and designing the circuit. For the
                design conception, we began by establishing the different possible states of the system. Based on these
                states, we defined the sequence of actions and reactions between the user and the system. The flowchart
                in Figure 1 illustrates the system's behavior depending on its state.
                <figure>
                    <img className="image" src={flowchart} alt="Flowchart of System Design" onClick={() => handleImageClick(flowchart)} />
                    <figcaption>Figure 1: Flowchart of the chosen system design</figcaption>
                </figure>
                We distinguish three general states: system initialization, system arming, and system disarming.
                <ul>
                    <li><strong>Initialization:</strong> When the code is uploaded to the board, the system
                        initialization process starts. The system allows the user to set their own code. The user is
                        prompted to enter a 4-6 digit alphanumeric code displayed on the screen with the message: 'Set
                        code:'. The user presses the "#" key to validate this process. If the entered code does not meet
                        the requirements, the process is ignored and repeated until a correct code is entered or the
                        system is interrupted (powered off). If the user enters a valid code, the system displays 'Arm:'
                        on the screen, allowing the user to arm the system.
                    </li>
                    <li><strong>Arming:</strong> To arm the system, the user must have completed the previous
                        initialization process. At this stage, the user must re-enter the set code. If it matches, the
                        system switches to the armed state, indicated by the red LED lighting up and the display showing
                        "System Armed". A 60-second countdown begins, displayed on the screen with the message 'Leave in
                        Xs' to allow the user to leave the secured area. At the end of this countdown, the alarm sounds
                        for 10 seconds, and the motion detector is activated. If motion is detected, a signal is sent to
                        the system to trigger the alarm. Detection continues indefinitely until the system is disarmed.
                        If the code is invalid, the system informs the user by displaying "Wrong code" and returns to
                        the previous state.
                    </li>
                    <li><strong>Disarming:</strong> To disarm the system, the user must re-enter the set code. If it
                        matches, the system switches to the disarmed state, indicated by the green LED lighting up and
                        the display showing "System Disarmed". If the code is invalid, the system warns the user with a
                        "Wrong code" message and returns to the previous state.
                    </li>
                </ul>
                <figure>
                    <img className="image" src={circuits} alt="Circuits" onClick={() => handleImageClick(circuits)} />
                    <figcaption>Figure 2: Circuit design</figcaption>
                </figure>
            </p>

            <section className="project-section">
                <h2 className="section-title">My Contribution</h2>
                <p className="text">
                    I specifically focused on coding the behavior for the password input mechanism and the buzzer. Using
                    STM32CubeIDE, I programmed the system to validate the input code and toggle the system's armed
                    state. The password setup mechanism was designed to ensure security and user-friendliness. During
                    initialization, the user is prompted to enter a 4-6 digit alphanumeric code. The system then
                    validates this input and stores it securely. If the code is valid, the user can arm the system by
                    re-entering the code. The buzzer was programmed to sound when the PIR sensor detected motion,
                    providing an immediate audible alert to potential security breaches. Additionally, the buzzer
                    deactivates after a set period or when the system is disarmed. I also integrated the components and
                    managed the communication between them using FreeRTOS for efficient multitasking. This involved
                    setting up tasks for reading the keypad input, updating the LCD display, monitoring the PIR sensor,
                    and controlling the buzzer.
                </p>
            </section>

            <h2 className="section-title">Demonstration</h2>
            <p>The video below is a brief demonstration of the alarm system in action.</p>
            <div className="video-container">
                <video controls width="100%" height="auto">
                    <source src={lab} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

            <h2 className="section-title">Note</h2>
            <p className="text note">
                This was a common project, and the information shared here is what my colleagues were comfortable with.
                My focus was primarily on designing the behaviour of the overall system, specifically the password input
                mechanism and the buzzer.
            </p>
            <footer className="project-footer">
                <div className="social-links">
                    <a href="https://www.linkedin.com/in/molly-kanyatsi-23a2591b9" target="_blank"
                       rel="noopener noreferrer">
                        <FaLinkedin size={30} />
                    </a>
                    <a href="https://github.com/Molly-k/Alarm-system-.git" target="_blank" rel="noopener noreferrer">
                        <FaGithub size={30} />
                    </a>
                </div>
                <p>© 2024 Molly Kanyatsi. All rights reserved.</p>
            </footer>

            {fullScreenImage && (
                <div className="full-screen-image-container" onClick={handleCloseImage}>
                    <img src={fullScreenImage} alt="Full Screen" className="full-screen-image" />
                </div>
            )}
        </motion.div>
    );
};

export default RealTimeAlarmSystem;
