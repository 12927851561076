import React, { useState } from 'react';
import './HospitalManagementSystem.css';
import { motion } from 'framer-motion';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import back from '../../assets/Hospital/purple.jpeg';
import angular from '../../assets/Hospital/angular.png';
import firebase from '../../assets/Hospital/firebase.png';
import uml from '../../assets/Hospital/uml.jpg';
import context from '../../assets/Hospital/context.jpg';
import pdfLogo from '../../assets/Hospital/pdficon.png';
import vid from '../../assets/Hospital/SEG_PRESENTATION_APPLICATION.mov';
import Livrable1_SEG3502 from '../../assets/Hospital/Livrable1_SEG3502.pdf';
import Livrable2_SEG3502 from '../../assets/Hospital/Livrable2_SEG.pdf';
import Livrable3_SEG3502 from '../../assets/Hospital/Livrable3_SEG3502.pdf';

const HospitalManagementSystem = () => {
    const [fullScreenImage, setFullScreenImage] = useState(null);

    const handleImageClick = (src) => {
        setFullScreenImage(src);
    };

    const handleCloseImage = () => {
        setFullScreenImage(null);
    };

    return (
        <motion.div className="container"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{duration: 0.5}}
        >
            <div className="header">
                <div className="header-left">
                    <img src={back} alt="Background" className="background-image"/>
                    <div className="title-container">
                        <h1 className="title">Patient Management System (PMS)</h1>
                        <p className="subtitle">Subsystem of the Hospital Management System</p>
                    </div>
                </div>
            </div>
            <p className="note">
                <strong><span>Note:</span> I am currently enhancing this project by using Angular for the
                    frontend and Spring Boot for the backend. This project is very important to me, and I felt our
                    initial work, though
                    substantial, could be further improved. Therefore, I am continuing to develop it as a personal
                    project,
                    which I will share upon completion. The project description below reflects the work my teammates and
                    I
                    completed for our software construction class project.</strong>
            </p>

            <p className="text">
                The Patient Management System (PMS) project was developed as part of our coursework. This project
                involved designing and implementing a comprehensive subsystem for managing patient information,
                appointments, and medical records within a hospital. The system is a crucial part of the larger Hospital
                Management System (HMS).
            </p>

            <h2 className="section-title">About The Project</h2>
            <p className="text">
                The primary goal of the PMS project is to provide an efficient and user-friendly solution for managing
                patient information, appointments, and medical records at Wellmeadows Hospital. The system integrates
                various patient management functions into a single cohesive platform, allowing medical staff to manage
                patient data, track appointments, and access medical records more effectively. The project involved
                developing a context diagram, an extensive description of the domain including subdomains, UML class
                diagrams, use cases and implementing the system using Firebase for the backend and Angular for the
                frontend.
            </p>

            <section className="project-section">
                <h2 className="section-title">Technologies Involved</h2>
                <p className="text">
                    The project utilized the following technologies:
                </p>
                <div className="components-grid">
                    <div className="component">
                        <img src={angular} alt="Angular" className="component-image image"
                             onClick={() => handleImageClick(angular)}/>
                        <p><strong>Angular</strong>: For developing the client-side user interface.</p>
                    </div>
                    <div className="component">
                        <img src={firebase} alt="Firebase" className="component-image image"
                             onClick={() => handleImageClick(firebase)}/>
                        <p><strong>Firebase</strong>: For implementing the backend and database.</p>
                    </div>
                </div>
            </section>

            <h2 className="section-title">System Design</h2>
            <p className="text">
                The design of the PMS involved several key aspects:
                <ul>
                    <li><strong>Context Diagram:</strong> The context diagram provides an overview of the system and its
                        interactions with external entities, including patients, staff, and other subsystems of the HMS.
                    </li>
                    <li><strong>Domain Description:</strong> An extensive description of the domain, including
                        subdomains such as patient management, admission and transfer of patients, prescription
                        management, and user management.
                    </li>
                    <li><strong>UML Class Diagrams:</strong> UML class diagrams represent the static structure of the
                        system, showing the system's classes, their attributes, operations, and relationships among
                        objects.
                    </li>
                    <li><strong>Use Cases:</strong> Use cases describe the functional requirements of the system,
                        capturing the interactions between users and the system to achieve specific goals.
                    </li>
                </ul>
                <figure>
                    <img className="image" src={context} alt="Context Diagram"
                         onClick={() => handleImageClick(context)}/>
                    <figcaption>Figure 1: Context Diagram</figcaption>
                </figure>
                <figure>
                    <img className="image" src={uml} alt="UML Class Diagram" onClick={() => handleImageClick(uml)}/>
                    <figcaption>Figure 2: UML Class Diagram</figcaption>
                </figure>
            </p>
            <p className="note">
                <span> Note: </span> These images can be found in the documentation below with more context.
            </p>

            <section className="project-section">
                <h2 className="section-title">My Contribution</h2>
                <p className="text">
                    My primary contributions to this project included:
                    <ul>
                        <li>Writing the executive summary, formulating hypotheses, and creating the system context
                            diagram and subdomain descriptions.
                        </li>
                        <li>Developing contract scenarios, creating the storyboard, and implementing the user interface
                            and server-side components.
                        </li>
                        <li>Designing UML class diagrams for all subdomains and creating sequence diagrams (level 1 and
                            2) for the use cases.
                        </li>
                        <li>Developing and implementing the backend and frontend logic for the Doctor, Charge Nurse, and
                            Admin use cases.
                        </li>
                    </ul>
                </p>
            </section>

            <h2 className="section-title">Documentation</h2>
            <p className="text">
                Below are links to detailed documentation that can be viewed in full screen:
            </p>
            <div className="components-grid">
                <div className="component">
                    <a href={Livrable1_SEG3502} target="_blank" rel="noopener noreferrer">
                        <img src={pdfLogo} alt="PDF Document" className="component-image image"/>
                        <p><strong>Project Document 1</strong></p>
                    </a>
                </div>
                <div className="component">
                    <a href={Livrable2_SEG3502} target="_blank" rel="noopener noreferrer">
                        <img src={pdfLogo} alt="PDF Document" className="component-image image"/>
                        <p><strong>Project Document 2</strong></p>
                    </a>
                </div>
                <div className="component">
                    <a href={Livrable3_SEG3502} target="_blank" rel="noopener noreferrer">
                        <img src={pdfLogo} alt="PDF Document" className="component-image image"/>
                        <p><strong>Project Document 3</strong></p>
                    </a>
                </div>
            </div>

            <h2 className="section-title">Demonstration</h2>
            <p>The video below is a brief demonstration of the PMS system in action.</p>
            <div className="video-container">
                <video controls width="100%" height="auto">
                    <source src={vid} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </div>

            <h2 className="section-title">Note</h2>
            <p className="text note">
                This project was a collaborative effort, and the information shared here is based on what my colleagues
                were comfortable with. My focus was primarily on writing the executive summary, creating the system
                context diagram and subdomain descriptions, designing UML class diagrams, developing sequence diagrams
                for key use cases, implementing the user interface and server-side components, and updating the GitHub
                repository with relevant code.
            </p>
            <footer className="project-footer">
                <div className="social-links">
                    <a href="https://www.linkedin.com/in/molly-kanyatsi-23a2591b9" target="_blank"
                       rel="noopener noreferrer">
                        <FaLinkedin size={30}/>
                    </a>
                    <a href="https://github.com/SEG3502A23/projet-groupe-6.git" target="_blank" rel="noopener noreferrer">
                        <FaGithub size={30}/>
                    </a>
                </div>
                <p>© 2024 Molly Kanyatsi. All rights reserved.</p>
            </footer>

            {fullScreenImage && (
                <div className="full-screen-image-container" onClick={handleCloseImage}>
                    <img src={fullScreenImage} alt="Full Screen" className="full-screen-image"/>
                </div>
            )}
        </motion.div>
    );
};

export default HospitalManagementSystem;
