import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { About, Footer, Header, Skills, Projects } from './container';
import { Navbar } from './components';
import './App.scss';
import { SnowplowRobot, HospitalManagementSystem, RealTimeAlarmSystem, Guess, Hotel } from './pages';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const App = () => {
    const location = useLocation();
    const showNavbar = location.pathname === "/";

    return (
        <div className='app'>
            {showNavbar && <Navbar />}
            <ScrollToTop />
            <Routes>
                <Route path="/" element={
                    <>
                        <Header />
                        <About />
                        <Projects />
                        <Skills />
                        <Footer />
                    </>
                } />
                <Route path="/projects/snowplow-robot" element={<SnowplowRobot />} />
                <Route path="/projects/guess-mobile-game" element={<Guess />} />
                <Route path="/projects/hospital-management-system-hms" element={<HospitalManagementSystem />} />
                <Route path="/projects/real-time-alarm-system" element={<RealTimeAlarmSystem />} />
                <Route path="/projects/e-hotel-booking-system" element={<Hotel />} />
            </Routes>
        </div>
    );
};

const WrappedApp = () => (
    <Router>
        <App />
    </Router>
);

export default WrappedApp;