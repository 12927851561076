import React from 'react';
import './Guess.css';
import { motion } from 'framer-motion';
import { FaLinkedin, FaGithub } from 'react-icons/fa';
import purplebubbles from '../../assets/Guess/purplebubbles.jpg';
import guess from '../../assets/Guess/guess.mov';

const Guess = () => {
    return (
        <motion.div className="container"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
        >
            <div className="header">
                <div className="header-left">
                    <img src={purplebubbles} alt="Purple Bubbles" className="background-image" />
                    <div className="title-container">
                        <h1 className="title">Guess Game</h1>
                        <p className="subtitle">Software</p>
                    </div>
                </div>
            </div>
            <p className="text">
                This game was developed as a personal project during my second year, marking my first
                experience with coding. I used a combination of XML and JavaScript on Android Studio.
            </p>

            <h2 className="section-title">About The Project</h2>
            <p className="text">
                The Guess Game, as the name suggests, is a guessing game. Upon launch, the user picks a topic
                from four proposed topics (Maths, Music, Geography, and Logo). After selecting a topic, the game
                begins with questions related to the chosen topic. Each question is worth 5 points for a correct answer.
                Additionally, the user has 15 seconds to answer each question. A decreasing timer and an accumulating
                score counter are displayed on the screen for each question.
            </p>

            <section className="project-section">
                <h2 className="section-title">Demonstration</h2>
                <p>The video below is a brief demonstration of the game.</p>
                <div className="video-container">
                    <video controls width="100%" height="auto">
                        <source src={guess} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </section>

            <h2 className="section-title">Note</h2>
            <p className="text note">
                This game was my first ever personal software project. I believe it deserves a place on my portfolio
                as a reminder of how far I've come. I also see potential in this game and would like to improve it in the future.
            </p>

            <footer className="project-footer">
                <div className="social-links">
                    <a href="https://www.linkedin.com/in/molly-kanyatsi-23a2591b9" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin size={30} />
                    </a>
                    <a href="https://github.com/Molly-k/Guess_game.git" target="_blank" rel="noopener noreferrer">
                        <FaGithub size={30} />
                    </a>
                </div>
                <p>© 2024 Molly Kanyatsi. All rights reserved.</p>
            </footer>
        </motion.div>
    );
};

export default Guess;
