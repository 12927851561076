import React from 'react';
import { FaLinkedin } from 'react-icons/fa';


const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
            <a href="https://www.linkedin.com/in/molly-kanyatsi-23a2591b9/" target="_blank"
               rel="noopener noreferrer">
                <FaLinkedin/>
            </a>
        </div>
        </div>
  )
}

export default SocialMedia