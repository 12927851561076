import about01 from '../assets/about01.png';
import about02 from '../assets/about02.png';
import about03 from '../assets/about03.png';
import about04 from '../assets/about04.png';
import ang from '../assets/ang.png';
import api from '../assets/api.png';
import backend from '../assets/backend.png';
import bgIMG from '../assets/bgIMG.png';
import bgWhite from '../assets/bgWhite.png';
import creator from '../assets/creator.png';
import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import cpp from '../assets/cpp.png';
import css from '../assets/css.png';
import docker from '../assets/docker.png';
import figma from '../assets/figma.png';
import flutter from '../assets/flutter.png';
import git from '../assets/git.png';
import html from '../assets/html.png';
import java from '../assets/java.png';
import javascript from '../assets/javascript.png';
import kotlin from '../assets/kotlin.png';
import metallica from '../assets/metallica.png';
import mongodb from '../assets/mongodb.png';
import node from '../assets/node.png';
import python from '../assets/python.png';
import postgreesql from '../assets/postgreesql.png';
import react from '../assets/react.png';
import sass from '../assets/sass.png';
import spring from '../assets/spring.png';
import typescript from '../assets/typescript.png';
import web from '../assets/web.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';
import systemdesign from '../assets/systemdesign.png';




export default {
  ang,
  backend,
  bgIMG,
  bgWhite,
  docker,
  email,
  mobile,
  creator,
  api,
  cpp,
  css,
  figma,
  flutter,
  git,
  kotlin,
  html,
  javascript,
  metallica,
  mongodb,
  node,
  python,
  postgreesql,
  react,
  sass,
  spring,
  typescript,
  about01,
  about02,
  about03,
  about04,
  circle,
  logo,
  java,
  web,
  systemdesign
};