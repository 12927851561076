import React, { useState } from 'react';
import './eHotel.css';
import { motion } from 'framer-motion';
import { FaLinkedin } from 'react-icons/fa';
import back from '../../assets/Hotel/back.jpeg';
import postgre from '../../assets/Hotel/postgre.png';
import flutter from '../../assets/Hotel/flutter.png';
import python from '../../assets/Hotel/python.jpeg';
import sql from '../../assets/Hotel/sql.png';
import er from '../../assets/Hotel/er.jpg';
import relational from '../../assets/Hotel/relational.jpg';
import vid from '../../assets/Hotel/Final_Video_Presentation_Group63_CSI2132.mov'

const Hotel = () => {
    const [fullScreenImage, setFullScreenImage] = useState(null);

    const handleImageClick = (src) => {
        setFullScreenImage(src);
    };

    const handleCloseImage = () => {
        setFullScreenImage(null);
    };

    return (
        <motion.div className="container"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{duration: 0.5}}
        >
            <div className="header">
                <div className="header-left">
                    <img src={back} alt="Background" className="background-image"/>
                    <div className="title-container">
                        <h1 className="title">eHotel Database Project</h1>
                        <p className="subtitle">Database Design & Implementation</p>
                    </div>
                </div>
            </div>
            <p className="text">
                The eHotel project was developed as part of our coursework in the Database I course. This project
                involved designing and implementing a comprehensive database for managing hotel chains, their hotels,
                and room bookings, with a focus on real-time room availability and booking functionality.
            </p>

            <h2 className="section-title">About The Project</h2>
            <p className="text">
                The primary goal of the eHotel project is to provide an efficient database solution for five well-known
                hotel chains operating in North America. The database allows customers to easily book rooms in
                real-time, view room availability, and manage bookings and rentings. The project involved developing an
                ER diagram, relational database schema, and implementing the database using PostgreSQL.
            </p>

            <section className="project-section">
                <h2 className="section-title">Technologies Involved</h2>
                <p className="text">
                    The project utilized the following technologies:
                </p>
                <div className="components-grid">
                    <div className="component">
                        <img src={postgre} alt="PostgreSQL" className="component-image image"
                             onClick={() => handleImageClick('path_to_postgresql_image')}/>
                        <p><strong>PostgreSQL</strong>: For implementing the relational database.</p>
                    </div>
                    <div className="component">
                        <img src={sql} alt="SQL" className="component-image image"
                             onClick={() => handleImageClick('path_to_sql_image')}/>
                        <p><strong>SQL</strong>: For creating the database schema, populating the database, and querying
                            data.</p>
                    </div>
                    <div className="component">
                        <img src={python} alt="Python" className="component-image image"
                             onClick={() => handleImageClick('path_to_python_image')}/>
                        <p><strong>Python</strong>: For server-side programming.</p>
                    </div>
                    <div className="component">
                        <img src={flutter} alt="Flutter" className="component-image image"
                             onClick={() => handleImageClick('path_to_flutter_image')}/>
                        <p><strong>Flutter</strong>: For the client-side user interface.</p>
                    </div>
                </div>
            </section>


            <h2 className="section-title">System Design</h2>
            <p className="text">
                The design of the eHotel system involved three main aspects: creating the ER diagram, developing the
                relational database schema, and defining integrity constraints.
                <ul>
                    <li><strong>ER Diagram:</strong> The ER diagram represents the entities and relationships within the
                        eHotel system, including hotel chains, hotels, rooms, customers, and employees.
                    </li>
                    <li><strong>Relational Database Schema:</strong> The schema defines the tables and relationships
                        based on the ER diagram. This includes tables for hotel chains, hotels, rooms, customers,
                        bookings, and rentings.
                    </li>
                    <li><strong>Integrity Constraints:</strong> These constraints ensure the correctness of the
                        database, including primary keys, foreign keys, domain constraints, and user-defined
                        constraints.
                    </li>
                </ul>
                <figure>
                    <img className="image" src={er} alt="ER Diagram"
                         onClick={() => handleImageClick(er)}/>
                    <figcaption>Figure 1: ER Diagram</figcaption>
                </figure>
                <figure>
                    <img className="image" src={relational} alt="Relational Database Schema"
                         onClick={() => handleImageClick(relational)}/>
                    <figcaption>Figure 2: Relational Database Schema</figcaption>
                </figure>
            </p>

            <section className="project-section">
                <h2 className="section-title">My Contribution</h2>
                <p className="text">
                    My primary contribution to this project was in designing the database. This included creating the ER
                    diagram to represent the data model and the relational database schema to define the structure of
                    the database. Additionally, I participated in the implementation of the database using PostgreSQL,
                    ensuring the correct
                    application of integrity constraints and efficient data organization. I also developed SQL queries
                    for various operations, such as retrieving hotel chain
                    emails, calculating the total number of rooms.
                </p>
                <p className="text">
                    Furthermore, I worked on creating indexes to optimize database performance, defining and
                    implementing views for easier data retrieval, and developing triggers to automate certain database
                    operations. This involved implementing at least three indexes on key database relations, creating
                    two views to show available rooms per area and the aggregated capacity of rooms in specific hotels,
                    and developing SQL triggers to ensure data integrity and enforce business rules.
                </p>
            </section>

            <h2 className="section-title">Demonstration</h2>
            <p>The video below is a brief demonstration of the eHotel database system in action.</p>
            <div className="video-container">
                <video controls width="100%" height="auto">
                    <source src={vid} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </div>

            <h2 className="section-title">Note</h2>
            <p className="text note">
                This project was a collaborative effort, and the information shared here is based on what my colleagues
                were comfortable with. My focus was primarily on the database design and implementation aspects.
            </p>
            <footer className="project-footer">
                <div className="social-links">
                    <a href="https://www.linkedin.com/in/molly-kanyatsi-23a2591b9" target="_blank"
                       rel="noopener noreferrer">
                        <FaLinkedin size={30}/>
                    </a>
                </div>
                <p>© 2024 Molly Kanyatsi. All rights reserved.</p>
            </footer>

            {fullScreenImage && (
                <div className="full-screen-image-container" onClick={handleCloseImage}>
                    <img src={fullScreenImage} alt="Full Screen" className="full-screen-image"/>
                </div>
            )}
        </motion.div>
    );
};

export default Hotel;
